import React from 'react'
import "../styles.css";

const Hello = ()  => {
  return (
    <body>{'Hello World!'}</body> 
  )
}

export default function App() {
  return (
    <div className="App">
      <Hello />
    </div>
  );
}
